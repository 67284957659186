(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/event-by-product-list-widget/assets/javascripts/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/sport/event-by-product-list-widget/assets/javascripts/handlebars-helpers.js');
"use strict";


if (typeof exports === 'object') {
  registerHelpers(require('hbs'));
} else {
  registerHelpers(Handlebars);
}
function registerHelpers(hbs) {
  hbs.registerHelper('increment', index => (index || 0) + 1);
}

 })(window);