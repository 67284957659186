(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/event-by-product-list-widget/views/index.js') >= 0) return;  svs.modules.push('/components/sport/event-by-product-list-widget/views/index.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.sport=_svs.sport||{};
_svs.sport.event_by_product_list_widget=_svs.sport.event_by_product_list_widget||{};
_svs.sport.event_by_product_list_widget.templates=_svs.sport.event_by_product_list_widget.templates||{};
svs.sport.event_by_product_list_widget.templates.index = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"product-hot-widget widget-default\">\n  <div class=\"align-center f-content content-reveal-headline\">\n    <h2 class=\"h1 f-section-title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":44}}}) : helper)))
    + "</h2>\n  </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"eventsByProduct") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"<",4,{"name":"compare","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":23,"column":14}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"component")||(depth0 && lookupProperty(depth0,"component"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"content-reveal",{"name":"component","hash":{"maxColumnCount":4,"useScroll":(depth0 != null ? lookupProperty(depth0,"useScroll") : depth0),"class":"js-content-reveal-event-by-products product-hot-item-list product-hot-center","equalHeight":true,"items":(depth0 != null ? lookupProperty(depth0,"banners") : depth0),"listClass":"event-by-product-list"},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":14,"column":16}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"eventsByProduct") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":13}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"component")||(depth0 && lookupProperty(depth0,"component"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"content-reveal/item",{"name":"component","hash":{"useScroll":(depths[1] != null ? lookupProperty(depths[1],"useScroll") : depths[1])},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":12,"column":18}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-sport-event_by_product_list_widget-item"),depth0,{"name":"components-sport-event_by_product_list_widget-item","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"component")||(depth0 && lookupProperty(depth0,"component"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"content-reveal",{"name":"component","hash":{"inverted":(depth0 != null ? lookupProperty(depth0,"inverted") : depth0),"maxColumnCount":4,"useScroll":(depth0 != null ? lookupProperty(depth0,"useScroll") : depth0),"class":"js-content-reveal-event-by-products product-hot-item-list","equalHeight":true,"items":(depth0 != null ? lookupProperty(depth0,"banners") : depth0),"listClass":"event-by-product-list"},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":2},"end":{"line":22,"column":16}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"eventsByProduct") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":25,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-sport-event_by_product_list_widget-index'] = svs.sport.event_by_product_list_widget.templates.index;
})(svs, Handlebars);


 })(window);