(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/event-by-product-list-widget/views/item.js') >= 0) return;  svs.modules.push('/components/sport/event-by-product-list-widget/views/item.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.sport=_svs.sport||{};
_svs.sport.event_by_product_list_widget=_svs.sport.event_by_product_list_widget||{};
_svs.sport.event_by_product_list_widget.templates=_svs.sport.event_by_product_list_widget.templates||{};
svs.sport.event_by_product_list_widget.templates.item = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isHighFive") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"size":150,"brand":(lookupProperty(helpers,"toLowerCase")||(depth0 && lookupProperty(depth0,"toLowerCase"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"brandLogo") : depth0),{"name":"toLowerCase","hash":{},"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":62}}})},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":73}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"size":100,"brand":(lookupProperty(helpers,"toLowerCase")||(depth0 && lookupProperty(depth0,"toLowerCase"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"brandLogo") : depth0),{"name":"toLowerCase","hash":{},"data":data,"loc":{"start":{"line":10,"column":34},"end":{"line":10,"column":62}}})},"data":data,"loc":{"start":{"line":10,"column":14},"end":{"line":10,"column":73}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"product-hot-info-container\">\n          HIGH FIVE\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"product-hot-sub-title js-product-hot-sub-title-animated\">\n          <div class=\"animation-container\">\n            <div class=\"inner\">\n              <div class=\"subtitle-one\">\n                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"subTitle") : depth0), depth0))
    + "\n              </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"extraSubTitle") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),">",37,{"name":"compare","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":27,"column":14},"end":{"line":35,"column":26}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"subtitle-two subtitle-two-animate\">\n                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"extraSubTitle") : depth0), depth0))
    + "\n              </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"subtitle-two\">\n                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"extraSubTitle") : depth0), depth0))
    + "\n              </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"product-hot-sub-title\">\n          "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"subTitle") : depth0), depth0))
    + "\n        </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"notranslate product-hot-funds "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasJackpot") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":50},"end":{"line":45,"column":105}}})) != null ? stack1 : "")
    + "\">\n          "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"extraFund") : depth0), depth0))
    + "\n        </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "product-hot-funds-jackpot";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"product-hot-info-container f-medium\">\n          <div class=\"product-hot-info\">\n            <div class=\"product-hot-info-small-text\">\n              "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"smallText") : depth0), depth0))
    + "\n            </div>\n            <div class=\"product-hot-info-text\">\n              "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "\n            </div>\n          </div>\n        </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"product-hot-footnote f-medium\">\n        "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"footnote") : depth0), depth0))
    + "\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"js-product-hot-a\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"gameUrl") : depth0), depth0))
    + "\">\n  <div class=\"product-hot product-hot-"
    + alias2((lookupProperty(helpers,"toLowerCase")||(depth0 && lookupProperty(depth0,"toLowerCase"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"className") : depth0),{"name":"toLowerCase","hash":{},"data":data,"loc":{"start":{"line":2,"column":38},"end":{"line":2,"column":68}}}))
    + " js-product-hot-panel\" data-area-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"areaName") : depth0), depth0))
    + "\" data-track=\"aktuella-kuponger"
    + alias2((lookupProperty(helpers,"increment")||(depth0 && lookupProperty(depth0,"increment"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"increment","hash":{},"data":data,"loc":{"start":{"line":2,"column":155},"end":{"line":2,"column":175}}}))
    + "/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"className") : depth0), depth0))
    + "\" data-impression=\"true\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"gameUrl") : depth0), depth0))
    + "\">\n    <div class=\"product-hot-container\">\n      <div class=\"product-hot-title-container\">\n        <div class=\"product-hot-product-title\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"brandLogo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":12,"column":17}}})) != null ? stack1 : "")
    + "          <i class=\"icon fc-white icon-300 icon-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></i>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isHighFive") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"extraSubTitle") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"extraFund") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"product-hot-info-rows\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"infoRows") : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":62,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"footnote") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":6},"end":{"line":68,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</a>\n";
},"useData":true});
Handlebars.partials['components-sport-event_by_product_list_widget-item'] = svs.sport.event_by_product_list_widget.templates.item;
})(svs, Handlebars);


 })(window);