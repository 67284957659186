(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/event-by-product-list-widget/assets/javascripts/get-sport-type-config.js') >= 0) return;  svs.modules.push('/components/sport/event-by-product-list-widget/assets/javascripts/get-sport-type-config.js');
"use strict";


const {
  SportTypeToString
} = svs.isServer ? require('trinidad-core').core.components.require('sport', 'sport-types').api : svs.components.sport.sportTypes;
const ONE_SPORT = 'ONE_SPORT';
const TWO_SPORTS = 'TWO_SPORTS';
const ALL_SPORTS = 'ALL_SPORTS';
const CONFIG_TYPE_HIGH_FIVE_LOWERCASE = 'high five';

function getSportTypeConfig(cmsConfig) {
  const sports = cmsConfig.sports || {};

  const result = {
    cacheKey: '',
    isAllSports: false,
    isUsingDrawApi: false,
    isUsingRacingApi: false,
    sportTypeListDraw: [],
    searchFilterDraw: ''
  };
  const _processSingleSport = swedishSportTitle => {
    const title = swedishSportTitle.toLowerCase();
    if (title === CONFIG_TYPE_HIGH_FIVE_LOWERCASE) {
      result.isUsingRacingApi = true;
      return;
    }
    const type = Object.keys(SportTypeToString).find(key => SportTypeToString[key].toLowerCase() === title);
    if (type && !result.sportTypeListDraw.includes(type)) {
      result.isUsingDrawApi = true;
      result.sportTypeListDraw.push(type);
    }
  };
  switch (sports.displayType) {
    case ALL_SPORTS:
      result.cacheKey = "eventsByProduct_".concat(ALL_SPORTS);
      result.isAllSports = true;
      result.isUsingDrawApi = true;
      result.isUsingRacingApi = true;
      break;
    case TWO_SPORTS:
      result.cacheKey = "eventsByProduct_".concat(sports.firstSport, "_").concat(sports.secondSport);
      _processSingleSport(sports.firstSport);
      _processSingleSport(sports.secondSport);
      break;
    case ONE_SPORT:
    default:
      result.cacheKey = "eventsByProduct_".concat(sports.sport);
      _processSingleSport(sports.sport);
      break;
  }
  if (result.isUsingDrawApi) {
    if (result.sportTypeListDraw.length === 0) {
      result.searchFilterDraw = 'notfilter=payload.draw.sport;undefined';
    } else if (result.sportTypeListDraw.length === 1) {
      result.searchFilterDraw = "andfilter=payload.draw.sport.type;".concat(result.sportTypeListDraw[0]);
    } else if (result.sportTypeListDraw.length > 1) {
      result.searchFilterDraw = "orfilter=".concat(result.sportTypeListDraw.map(type => "payload.draw.sport.type;".concat(type)).join(','));
    }
  }
  return result;
}

if (svs.isServer) {
  module.exports = getSportTypeConfig;
} else {
  setGlobal('svs.components.sport.eventByProductListWidget', {
    getSportTypeConfig
  });
}

 })(window);