(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/event-by-product-list-widget/assets/javascripts/query-data-async.js') >= 0) return;  svs.modules.push('/components/sport/event-by-product-list-widget/assets/javascripts/query-data-async.js');
"use strict";



const jupiter = svs.isServer ? require('trinidad-core').core.jupiter : svs.core.jupiter;
const getLogger = svs.isServer ? require('trinidad-core').core.logger : svs.core.log.getLogger;
const logger = getLogger('widget:event-by-product-list');

function queryDataAsync(_ref) {
  let {
    target,
    sportTypeConfig,
    payload,
    req = null
  } = _ref;
  switch (target) {
    case 'draws':
      return _queryDraws(sportTypeConfig, req);
    case 'drawsJackpot':
      return _queryDrawsJackpot(sportTypeConfig, req);
    case 'racing':
      return _queryRacing(sportTypeConfig, req);
    case 'racingJackpot':
      return _queryRacingJackpot(sportTypeConfig, payload === null || payload === void 0 ? void 0 : payload.racingResponse, req);
    default:
      logger.info("Error in queryData(): Invalid target \"".concat(target, "\""));
      return null;
  }
}

function _queryDraws(sportTypeConfig, req) {
  const {
    isUsingDrawApi,
    searchFilterDraw
  } = sportTypeConfig;
  if (!isUsingDrawApi) {
    return null;
  }
  const ctx = ['draw_challenge', 'draw_bomben', 'draw_topptipset', 'draw_topptipsetextra', 'draw_topptipsetstryk', 'draw_stryktipset', 'draw_europatipset', 'draw_powerplay', 'draw_maltipset', 'draw_fulltraff'].join(',');
  const includes = ['payload.draw.productId', 'payload.draw.drawNumber', 'payload.draw.regCloseTime', 'payload.draw.currentNetSale', 'payload.draw.currentNetSales', 'payload.draw.fund', 'payload.draw.sport', 'payload.draw.events.eventNumber', 'payload.draw.events.eventDescription', 'payload.draw.events.match.matchId', 'payload.draw.events.match.participants.name', 'payload.draw.events.match.participants.mediumName', 'payload.draw.events.match.league.name', 'payload.draw.drawEvents.eventNumber', 'payload.draw.drawEvents.eventDescription', 'payload.draw.drawEvents.match.matchId', 'payload.draw.drawEvents.match.participants.name', 'payload.draw.drawEvents.match.participants.mediumName', 'payload.draw.drawEvents.match.league.name'].join(',');
  return jupiter.callAsync({
    method: 'GET',
    path: ["/search/1/query?ctx=".concat(ctx), "includes=".concat(includes), searchFilterDraw, 'sort=payload.draw.regCloseTime;asc', 'count=50'].join('&'),
    req
  });
}

function _queryDrawsJackpot(sportTypeConfig, req) {
  const {
    isUsingDrawApi
  } = sportTypeConfig;
  if (!isUsingDrawApi) {
    return null;
  }
  return jupiter.callAsync({
    method: 'GET',
    path: '/draw/1/jackpots',
    req
  });
}

function _queryRacing(sportTypeConfig, req) {
  const {
    isUsingRacingApi
  } = sportTypeConfig;
  if (!isUsingRacingApi) {
    return null;
  }
  const includes = ['payload.race.raceId', 'payload.race.raceName', 'payload.race.raceNumber', 'payload.race.countryCode', 'payload.race.gait', 'payload.race.distance', 'payload.race.distanceUnit', 'payload.race.startType', 'payload.race.startTimeTentative', 'payload.race.meeting.date', 'payload.race.meeting.meetingNumber', 'payload.race.meeting.track.trackName', 'payload.race.meeting.track.pathName'].join(',');
  return jupiter.callAsync({
    method: 'GET',
    path: ['/search/1/query?ctx=racing', 'type=race', "includes=".concat(includes), 'andfilter=searchMetadata.tags.userProducts;Rx5io,payload.race.raceDrawState;Opened', 'rangefilter=payload.race.startTimeTentative;gte;now', 'sort=payload.race.startTimeTentative;asc', 'count=1'].join('&'),
    req
  });
}

function _queryRacingJackpot(sportTypeConfig, racingResponse, req) {
  const {
    isUsingRacingApi
  } = sportTypeConfig;
  if (!isUsingRacingApi || !racingResponse) {
    return null;
  }
  const racingIds = racingResponse.result.map(item => {
    var _item$payload;
    return item === null || item === void 0 || (_item$payload = item.payload) === null || _item$payload === void 0 || (_item$payload = _item$payload.race) === null || _item$payload === void 0 ? void 0 : _item$payload.raceId;
  }).filter(Boolean);
  if (racingIds.length === 0) {
    return null;
  }
  if (racingIds.length > 1) {
    logger.info('Warning: Got more than one racingId for High Five - not supported, yet');
  }
  return jupiter.callAsync({
    method: 'GET',
    path: "/racing/1/race/".concat(racingIds[0], "/turnover?userProduct=Rx5io"),
    req
  });
}

if (svs.isServer) {
  module.exports = queryDataAsync;
} else {
  setGlobal('svs.components.sport.eventByProductListWidget', {
    queryDataAsync
  });
}

 })(window);