(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/event-by-product-list-widget/assets/javascripts/event-by-product-list.js') >= 0) return;  svs.modules.push('/components/sport/event-by-product-list-widget/assets/javascripts/event-by-product-list.js');
"use strict";


const _getAllServerImports = () => {
  const path = require('path');
  const trinidad = require('trinidad-core').core;
  const localRequire = file => require(path.join(__dirname, file));
  localRequire('handlebars-helpers.es6');
  return Object.freeze({
    prepareTTLCache: () => new trinidad.ttlcache(),
    getSportTypeConfig: localRequire('get-sport-type-config.es6'),
    queryDataAsync: localRequire('query-data-async.es6'),
    extractEventItems: localRequire('./extract-event-items.es6')
  });
};
const _getAllClientImports = svs => Object.freeze({
  prepareTTLCache: () => {},
  getSportTypeConfig: svs.components.sport.eventByProductListWidget.getSportTypeConfig,
  queryDataAsync: svs.components.sport.eventByProductListWidget.queryDataAsync,
  extractEventItems: svs.components.sport.eventByProductListWidget.extractEventItems
});
const {
  prepareTTLCache,
  getSportTypeConfig,
  queryDataAsync,
  extractEventItems
} = svs.isServer ? _getAllServerImports() : _getAllClientImports(svs);
const ttlCache = prepareTTLCache();
const ONE_MINUTE = 60 * 1000;
const MAX_NUMBER_OF_MATCHES = 8;


async function getEventsByFilter(req, res, context) {
  const cmsConfig = context.config;
  const areaName = context.area;
  const title = cmsConfig.title;

  const sportTypeConfig = getSportTypeConfig(cmsConfig);
  const {
    cacheKey
  } = sportTypeConfig;
  let responses = ttlCache.get(cacheKey);
  if (!responses) {
    const [draws, drawsJackpot, racing] = await Promise.all([queryDataAsync({
      target: 'draws',
      sportTypeConfig,
      req
    }), queryDataAsync({
      target: 'drawsJackpot',
      sportTypeConfig,
      req
    }), queryDataAsync({
      target: 'racing',
      sportTypeConfig,
      req
    })]);
    const racingJackpot = await queryDataAsync({
      target: 'racingJackpot',
      sportTypeConfig,
      payload: {
        racingResponse: racing
      },
      req
    });
    responses = {
      draws,
      drawsJackpot,
      racing,
      racingJackpot
    };
    ttlCache.set(cacheKey, responses, 5 * ONE_MINUTE);
  }
  const eventsByProduct = [...extractEventItems({
    target: 'draws',
    response: responses.draws,
    req,
    jackpotsResponse: responses.drawsJackpot,
    cmsConfig,
    areaName
  }), ...extractEventItems({
    target: 'racing',
    response: responses.racing,
    req,
    jackpotsResponse: responses.racingJackpot,
    cmsConfig,
    areaName
  })];
  eventsByProduct.sort((a, b) => b.sortNetSale - a.sortNetSale);
  eventsByProduct.sort((a, b) => a.sortTS - b.sortTS);
  eventsByProduct.splice(MAX_NUMBER_OF_MATCHES);
  return {
    title,
    eventsByProduct
  };
}

if (svs.isServer) {
  module.exports = {
    getEventsByFilter
  };
} else {
  setGlobal('svs.components.sport.eventByProductListWidget', {
    getEventsByFilter
  });
}

 })(window);