(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/event-by-product-list-widget/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/sport/event-by-product-list-widget/assets/javascripts/init.js');
"use strict";



const {
  detect
} = svs.core;
const {
  analytics,
  ContentReveal
} = svs.components;
function init(options) {
  const $el = options.$el;
  new ContentReveal({
    el: $el.find('.js-content-reveal-event-by-products')
  });
  const el = options.$el.get(0);
  initEventListeners(el);
}
function initEventListeners(el) {
  [...el.querySelectorAll('.js-product-hot-panel')].forEach(el => el.addEventListener('click', onDrawClick));
  if (detect.formfactor.desktop()) {
    [...el.querySelectorAll('.js-content-reveal-list-item')].forEach(el => el.addEventListener('mouseenter', onHover));
    [...el.querySelectorAll('.js-content-reveal-list-item')].forEach(el => el.addEventListener('mouseleave', onLeave));
  } else {
    [...el.querySelectorAll('.js-content-reveal-list-item')].forEach(el => onStartObserve(el));
  }
  el.querySelector('.js-product-hot-a').addEventListener('click', event => event.preventDefault());
}
function onStartObserve(el) {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      const {
        isIntersecting,
        intersectionRatio
      } = entry;
      if (isIntersecting) {
        if (intersectionRatio >= 0.20) {
          entry.target.classList.add('product-hot-sub-title-animate');
        } else {
          entry.target.classList.remove('product-hot-sub-title-animate');
        }
      } else {
        entry.target.classList.remove('product-hot-sub-title-animate');
      }
    });
  }, {
    threshold: [0, 0.2]
  });
  observer.observe(el);
}
function onLeave(e) {
  e.target.classList.remove('product-hot-sub-title-animate');
}
function onHover(e) {
  e.target.classList.add('product-hot-sub-title-animate');
}
function onDrawClick(e) {
  e.preventDefault();
  const url = this.getAttribute('href');
  trackClick(this, () => {
    window.location.href = url;
  });
}
function trackClick(el, callback) {
  const track = el.getAttribute('data-track');
  const areaName = el.getAttribute('data-area-name');
  if (track) {
    analytics.trackPromoClick({
      id: track,
      name: track,
      position: areaName
    }, callback);
  }
}

setGlobal('svs.components.sport.eventByProductListWidget', {
  init
});

 })(window);